import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIndicator,
  selectSelectedIndicators,
  selectSuggestedIndicators,
} from '@/store/slices/indicators';
import changeIndicatorStateThunk from '@/store/thunks/changeIndicatorStateThunk';
import { IndicatorId } from '@/store/types/basicTypes';
import { IndicatorActions } from '@/store/types/indicator';
import { AiIndicator, Chip, LabelWrapper } from './styled';
import { ChipVariant } from '@s-rm/react-ui-lib';

const CaseTypeIndicator = ({ indicatorId }: { indicatorId: IndicatorId }) => {
  const dispatch = useAppDispatch();
  const selectedIndicators = useAppSelector(selectSelectedIndicators);
  const suggestedIndicators = useAppSelector(selectSuggestedIndicators);
  const indicator = useAppSelector((state) =>
    selectIndicator(state, indicatorId)
  );
  const isSelected = selectedIndicators.includes(indicatorId);
  const isSuggested = suggestedIndicators.includes(indicatorId);

  const handleClick = () =>
    dispatch(
      changeIndicatorStateThunk({
        indicatorId,
        action: isSelected
          ? IndicatorActions.Deselect
          : IndicatorActions.Select,
      })
    );

  return (
    <Chip
      data-testid={`indicator-${indicatorId}`}
      aria-selected={isSelected}
      onClick={handleClick}
      colorVariant={(isSelected ? 'neutral' : 'transparent') as ChipVariant}
      rounded
      // @ts-ignore
      size='large'
      label={
        <LabelWrapper>
          {isSuggested && <AiIndicator>AI</AiIndicator>}
          <div>{indicator.label}</div>
        </LabelWrapper>
      }
    />
  );
};

export default CaseTypeIndicator;
